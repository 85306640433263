import React from "react";
import { Link } from "react-router-dom";
import{ Row, Col, Table } from "reactstrap";

import XTInsertionImg from 'assets/img/navbar/XT/Insertion/Series-8800-MPHT-Insertion.png';
import XTRInsertionImg from 'assets/img/navbar/XT/Insertion/Series-8200-MPHT-Insertion-Remote.png';

import '../../MobileSideNavbar.css';
import InlineIcon from  'assets/img/inline_primary.svg';
import InsertionIcon from  'assets/img/insertion_primary.svg';

function XT({mobileNavbarState}){
  const product_data = [
    {
      title: '525CR',
      link: '525cr',
      description: 'EPI’s High Temperature Flow Meter with an Explosion-Proof Rated Enclosure and Remote Electronics',
      type: 1,
      left_small_title: 'INSERTION',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      left_icon: InsertionIcon,
      image: XTRInsertionImg,
      default_image: XTRInsertionImg,
      left_image: XTRInsertionImg,
      right_image: XTRInsertionImg
    },
    {
      title: '200C',
      link: '200c',
      description: 'EPI’s High Temperature Flow Meter with an Explosion-Proof Rated Enclosure',
      type: 1,
      left_small_title: 'INSERTION',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      left_icon: InsertionIcon,
      image: XTInsertionImg,
      default_image: XTInsertionImg,
      left_image: XTInsertionImg,
      right_image: XTInsertionImg
    }
  ]

  const link_dir_inline = '/products/xt/inline/';
  const link_dir_insertion = '/products/xt/insertion/';

  return(
    <>
      <div id="xt" class="sidenav" style={{'padding-top': '50px'}}>
      <Table className="table-shopping" responsive>
        <tbody>
          { product_data.map((value) => {
            return(
              <tr>
                <td>
                  <div className="contentM text-center" style={{'width': '270px'}}>
                    <img style={{'width': '270px', 'height': 'auto'}} alt="..." src={value.image} ></img>
                    <p className="nav-product-title">{ value.title }</p>
                    <p className="nav-product-description">{ value.description }</p>
                    <Row>

                      <Col
                        style={{'font-weight': '600', 'color': '#095CF6'}}
                        tag={Link}
                        to={link_dir_insertion + value.link}
                        onClick={() => mobileNavbarState()}
                      >
                        Insertion
                      </Col>
                    </Row>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      </div>
    </>
  )
}

export default XT;
